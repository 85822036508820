@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100;200;300;400;500;600;700;800;900&display=swap');

.App {
    text-align: center;
    font-family: 'Roboto-Flex';
    /* width: 100vw; */
    /* height: 100vh; */
    background: #ffffff;
}

.black-back {
    background: #0c0c0c;
    color: #ffffff;
}

.logo {
    margin-top: 7vh;
    height: 10vh;
    pointer-events: none;
}

.text {
    font-size: 8vh;
    /* font-size: larger; */

    font-weight: 400;
    pointer-events: none;
}

.text-medium {
    font-size: 6vh;
    pointer-events: none;
}

.text-300 {
    font-weight: 300;
    pointer-events: none;
}

.text-400 {
    font-weight: 400;
    pointer-events: none;
}

.text-500 {
    font-weight: 500;
    pointer-events: none;
}

.text-600 {
    font-weight: 600;
    pointer-events: none;
}

.text-700 {
    font-weight: 700;
    pointer-events: none;
}

.text-800 {
    font-weight: 800;
    pointer-events: none;
}

.text-900 {
    font-weight: 900;
    pointer-events: none;
}

.text-5vh {
    font-size: 5vh;
}

.text-4vh {
    font-size: 4vh;
}

.text-3-5vh {
    font-size: 3.5vh;
}

.text-3vh {
    font-size: 3vh;
}

.text-struck-out {
    text-decoration: line-through;
}

.header {
    /* background-color: #ffffff; */
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(2vmin);
    color: #0c0c0c;
}

.btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0c0c0c;
    height: 10vh;
    width: 26vh;
    border-radius: 1vh;
    color: white;
    font-size: 5.5vh;

    border: 0 solid;
    box-shadow: inset 0 0 20px rgb(0, 0, 0);
    outline: 1px solid;
    outline-color: rgb(0, 0, 0);
    outline-offset: 0px;
    text-shadow: none;
    /* pointer-events: none; */
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn:hover {
    border-radius: 5vh;
    border: 1px solid;
    box-shadow: inset 0 0 20px rgb(0, 0, 0), 0 0 20px rgb(0, 0, 0);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 3vh;
}

.btn-text {
    pointer-events: none;
}

.btn-white {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    height: 10vh;
    width: 26vh;
    border-radius: 1vh;
    color: #0c0c0c;
    font-size: 5.5vh;
    margin-top: 1vh;

    border: 0 solid;
    box-shadow: inset 0 0 20px #ffffff;
    outline: 1px solid;
    outline-color: #ffffff;
    outline-offset: 0px;
    text-shadow: none;
    /* pointer-events: none; */
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn-white:hover {
    border-radius: 5vh;
    border: 1px solid;
    box-shadow: inset 0 0 20px #ffffff, 0 0 20px #ffffff;
    outline-color: #ffffff;
    outline-offset: 3vh;
}

.btn-text {
    pointer-events: none;
}

.arrow {
    height: 5vh;
    margin-top: 2vh;
    pointer-events: none;
}

.small-text {
    /* display: flex;
  flex-direction: row; */
    font-size: 4vh;
    /* margin-top: 8vh; */
    font-weight: 200;
    pointer-events: none;
}

.margin-top-1vh {
    margin-top: 1vh;
}

.margin-top-2vh {
    margin-top: 2vh;
}

.margin-top-3vh {
    margin-top: 3vh;
}

.page-1-margin-top {
    margin-top: 13vh;
}

.page-2-margin-top {
    margin-top: 5vh;
}

.small-text-1-page {
    margin-top: 14.5vh;
}

.small-text-2-page {
    margin-top: 4vh;
}

.arrow-container {
    display: flex;
    flex-direction: row;
    height: 40vh;
    width: 75vw;
}

.arrow-container-2 {
    display: flex;
    flex-direction: row;
    height: 33.5vh;
    width: 75vw;
}

.from-to {
    display: flex;
    flex-direction: column;
    font-size: 6vh;
    justify-self: center;
    align-self: center;
    align-items: center;
    justify-content: center;
    /* background-color: #0c0c0c; */
    flex: 1;
    /* margin-left: 20px; */
}

.or-and {
    margin-top: 1vh;
    font-size: 5.5vh;
    margin-bottom: 1vh;
}

.arrow-right-container {
    display: flex;
    align-self: center;
    justify-self: center;
}

.arrow-right {
    width: 7vw;
}

.how-container {
    height: 80vh;
    width: 97vw;
    display: flex;
    flex-direction: column;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
}

.half-container {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.half-half-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    color: #ffffff;
    font-size: 3vh;
}

.any-other {
    margin-top: 2.75vh;
    font-size: 2vh;
}

.margin-top-4-75vh {
    margin-top: 4.75vh;
}

.how-icon {
    height: 4.75vh;
    margin-top: 4vh;
}

.arrow-white {
    height: 3vh;
    justify-self: center;
    align-self: center;
}

div {
    font-family: 'Roboto Flex';
}

@media (max-width:600px) {
    .text {
        font-size: 5.5vh;
    }

    .small-text {
        /* margin-top: 10vh; */
        font-size: 2.5vh;
    }

    .page-1-small-text {
        font-size: 2vh;
    }

    .page-1-margin-top {
        margin-top: 14vh;
    }

    .arrow {
        margin-top: 1vh;
        height: 2.5vh;
    }

    .arrow-right-container {
        height: 10vh;
    }

    .arrow-right {
        width: 16vw;
        transform: rotate(90deg);
    }

    .arrow-right-2 {
        width: 4vw;
        transform: none;
    }

    .arrow-container-2 {
        /* flex-direction: column; */
    }

    .from-to-small {
        font-size: 1.75vh;
    }

    .and {
        font-size: 1.5vh;
    }

    .text-medium {
        font-size: 4vh;
    }

    .from-to {
        font-size: 3vh;
    }

    .from-to-2 {
        font-size: 2vh;
    }

    .half-container {
        flex-direction: column;
    }

    .half-half-container {
        font-size: 2vh;
    }

    .how-icon {
        height: 2.5vh;
        margin-top: 2.5vh;
    }

    .any-other {
        font-size: 1.5vh;
        margin-top: 1vh;
    }

    .arrow-white {
        transform: rotate(90deg);
        width: 3vw;
    }

    .from-to-first {
        flex: none;
        height: 30vh;
    }

    .btn-page-3 {
        margin-top: 10vh;
    }

    .or-and {
        font-size: 2vh;
    }

    .page-2-margin-top {
        margin-top: 2vh;
    }

    .arrow-container {
        flex-direction: column;
        height: 58vh;
    }

    .btn-white {
        margin-top: 3vh;
    }
}

/* Hide fullpage.js watermark */
.fp-watermark {
    display: none !important;
}

/* Or if that doesn't work */
#fp-nav + div:last-child {
    display: none !important;
}