.confirmation-page {
    background: white;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding: 2rem;
}

.confirmation-container {
    width: 100%;
    max-width: 800px;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 2rem;
}

.confirmation-container h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: black;
    margin-bottom: 3rem;
}

.confirmation-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
}

.main-message {
    font-size: 1.4rem;
    font-weight: 500;
    color: #000;
    max-width: 600px;
    margin: 0 auto;
}

.status-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.status-info p {
    font-size: 1.3rem;
    line-height: 1.6;
    color: #333;
}

.commitment {
    margin-top: 1rem;
    padding: 2rem;
    background: #f8f8f8;
    border-radius: 0.5rem;
}

.commitment p {
    font-size: 1.3rem;
    line-height: 1.6;
    color: #333;
}

.position-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
    padding: 2rem;
}

.position-number {
    font-size: 6rem;
    font-weight: 700;
    color: black;
    line-height: 1;
    margin-bottom: 1rem;
}

.position-label {
    font-size: 1.4rem;
    color: #666;
    font-weight: 500;
}

.follow-up-message {
    font-size: 1.4rem;
    color: #333;
    margin-top: 1rem;
}

.single-line {
    white-space: nowrap;
    font-size: clamp(1rem, 4vw, 2.5rem);
    line-height: 1.2;
    /* padding: 0 1rem; */
}

@media (max-width: 600px) {
    .confirmation-container h2 {
        font-size: 1.2rem;
    }

    .main-message {
        font-size: 1rem;
    }

    .status-info p,
    .commitment p {
        font-size: 1.1rem;
    }

    .position-number {
        font-size: 4rem;
    }

    .position-label {
        font-size: 1.2rem;
    }

    .follow-up-message {
        font-size: 1.2rem;
    }

    .single-line {
        font-size: clamp(0.8rem, 3vw, 1.5rem);
    }
} 