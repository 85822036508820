.form-page {
    background: white;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.form-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container h2 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    color: black;
    margin-bottom: 3rem;
}

.form-group {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
}

.form-input {
    width: 350px;
    padding: 1.5rem;
    font-size: 1.3rem;
    border: 2px solid #000;
    border-radius: 0.5rem;
    background: transparent;
    transition: all 0.3s ease;
}

.form-input:focus {
    outline: none;
    border-color: #666;
}

.form-input::placeholder {
    color: #999;
}

.form-submit-btn {
    width: 350px;
    /* margin: 2rem auto 0; */
    padding: 1.5rem;
    border: none;
    background: black;
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    border: 2px solid #000;
}

.form-submit-btn:hover {
    border-radius: 5vh;
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
}

.error-message {
    color: #ff0000;
    font-size: 0.9rem;
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.form-input.error {
    border-color: #ff0000;
}

.form-input.error:focus {
    border-color: #ff0000;
}

/* Remove number input arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

@media (max-width: 600px) {
    .form-container {
        padding: 1rem;
    }

    .form-container h2 {
        font-size: 2rem;
    }

    .form-input {
        width: 90%;
        font-size: 1.2rem;
        padding: 1.2rem;
    }

    .form-submit-btn {
        width: 90%;
        font-size: 1.6rem;
        padding: 1.2rem;
    }
}