.pricing-page {
    background: white;
    height: 100vh;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
}

.pricing-container {
    max-width: 100vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pricing-header {
    text-align: center;
    margin-top: 5rem;
    font-size: 3.5rem;
    font-weight: 700;
    color: black;
    margin-bottom: 5rem;
}

.pricing-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 0;
    margin: 0 auto;
}

.pricing-card {
    background: black;
    color: white;
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 300px;
}

.pricing-card:hover {
    transform: translateY(-8px);
}

.card-title {
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
}

.price-container {
    margin: 1rem 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.price {
    color: white;
    font-size: 2.5rem;
    font-weight: 700;
}

.period {
    color: #999;
    font-size: 1.2rem;
    margin-left: 0.5rem;
}

.description {
    color: #999;
    margin-bottom: 2rem;
    font-size: 1.1rem;
}

.start-button {
    width: 100%;
    padding: 1rem;
    border: none;
    background: white;
    color: black;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    border: 0 solid;
    box-shadow: inset 0 0 20px #ffffff;
    outline: 1px solid;
    outline-color: #ffffff;
    outline-offset: 0px;
    text-shadow: none;
    color: #0c0c0c;
    /* pointer-events: none; */
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.start-button:hover {
    border-radius: 5vh;
    border: 1px solid;
    box-shadow: inset 0 0 20px #ffffff, 0 0 20px #ffffff;
    outline-color: #ffffff;
    outline-offset: 1vh;
}

.pricing-badge {
    animation: pulse 2s infinite;
}

.pricing-button {
    transition: all 0.3s ease;
}

.pricing-button:hover {
    transform: scale(1.05);
}

.pricing-feature {
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
}

/* Stagger the feature animations */
.pricing-feature:nth-child(1) {
    animation-delay: 0.1s;
}

.pricing-feature:nth-child(2) {
    animation-delay: 0.2s;
}

.pricing-feature:nth-child(3) {
    animation-delay: 0.3s;
}

.pricing-feature:nth-child(4) {
    animation-delay: 0.4s;
}

.pricing-feature:nth-child(5) {
    animation-delay: 0.5s;
}

.pricing-feature:nth-child(6) {
    animation-delay: 0.6s;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.4);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }

}

.header {
    position: relative;
    width: 100%;
}

.logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    position: absolute;
    top: 0;
}

.pricing-logo {
    height: 8vh;
    width: auto;
    margin-top: 1vh;
}

/* Update team size style with smaller text */
.team-size {
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    margin: 1rem 0;
    letter-spacing: 0.05em;
}

/* Update media query for smaller screens */
@media (max-width: 600px) {
    .pricing-cards {
        flex-direction: column;
        overflow-y: auto;
        padding: 1rem;
        -webkit-overflow-scrolling: touch;
        gap: 1rem;
        height: 100%;
        align-items: center;
    }

    .pricing-card {
        width: 90%;
        max-width: 300px;
        flex-shrink: 0;
    }

    .pricing-header {
        font-size: 2rem;
        font-weight: 700;
        color: black;
        margin-bottom: 1rem;
    }

    .pricing-container {
        justify-content: flex-start;
        padding-top: 2rem;
    }
}